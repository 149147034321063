import { useFlags } from 'flagsmith/react';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { FeatureFlags, WebRoutes } from '@lawnstarter/customer-modules/enums';
import {
  getTrackingEventSource,
  useOrderServiceViewedTracking,
} from '@lawnstarter/customer-modules/hooks';
import { t } from '@lawnstarter/customer-modules/services';
import {
  orderServices_submitQuote,
  properties_currentPropertyIdSelector,
} from '@lawnstarter/customer-modules/stores/modules';
import { ExtendedYardLocations } from '@lawnstarter/ls-constants';
import { Button } from '@lawnstarter/ls-react-common/atoms';
import { ServiceType } from '@lawnstarter/ls-react-common/enums';
import { useApiTracking } from '@lawnstarter/ls-react-common/hooks';

import { OrderServiceHeader } from '@src/components';
import { GenericQuoteForm } from '@src/components/organisms/forms';
import { checkboxParser } from '@src/helpers';
import {
  useAutoScrollOnFormErrors,
  useDispatch,
  useRouteNavigation,
  useSelector,
} from '@src/hooks';
import { errorService } from '@src/services';

import { StyledFormContainer } from './styles';

import type { GenericServiceFormData } from '@src/components/organisms/forms';

export function OrderGenericServiceScreen({ serviceType }: { serviceType: ServiceType }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const { navigate } = useRouteNavigation();
  const isUpdating = useSelector(({ orderServices }) => orderServices.loadingStatus.isUpdating);
  const carouselAbTest = useFlags([FeatureFlags.RECOMMENDATION_CAROUSEL_AB_TEST])[
    FeatureFlags.RECOMMENDATION_CAROUSEL_AB_TEST
  ];

  const apiTracking = useApiTracking()!;
  const source = carouselAbTest.enabled
    ? getTrackingEventSource(location.state?.source, carouselAbTest.value)
    : undefined;
  useOrderServiceViewedTracking(apiTracking, serviceType, errorService, source);

  const {
    control,
    handleSubmit,
    formState: { errors, submitCount },
  } = useForm<GenericServiceFormData>();

  const propertyId = useSelector(properties_currentPropertyIdSelector);
  const serviceInfo = useMemo(() => {
    switch (serviceType) {
      case ServiceType.TreeCare:
        return { id: 3, name: t('order.treeCare.title') };
      case ServiceType.Landscaping:
        return { id: 9, name: t('order.landscaping.title') };
      default:
        return { id: 18, name: t('order.other.title') };
    }
  }, [serviceType]);

  useAutoScrollOnFormErrors({ errors, submitCount });

  const submit = async ({
    budget,
    locations,
    pendingImages,
    desired_work,
    property_items,
  }: GenericServiceFormData) => {
    const result = await dispatch(
      orderServices_submitQuote({
        updatedFields: {
          property_id: propertyId,
          service_id: serviceInfo.id,
          service_str_id: serviceType,

          // Form data
          budget,
          pendingImages,
          itemDetails: property_items,
          desiredWorkDetails: desired_work,
          locations: checkboxParser(locations, ExtendedYardLocations),
        },
        source,
      }),
    );

    if (result?.success) {
      navigate(WebRoutes.services);
    }
  };

  return (
    <StyledFormContainer>
      <OrderServiceHeader
        section={t('forms.quote.request')}
        serviceName={serviceInfo.name}
        showMQSteps
      />

      <form className="my-4 gap-6 flex flex-col">
        <GenericQuoteForm control={control} isUpdating={isUpdating} />
        <Button
          trackID="submit"
          disabled={isUpdating}
          loading={isUpdating}
          mode="contained"
          onPress={handleSubmit(submit)}
        >
          {t('forms.getPrice')}
        </Button>
      </form>
    </StyledFormContainer>
  );
}
