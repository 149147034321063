import { useFlags } from 'flagsmith/react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { FeatureFlags, WebRoutes } from '@lawnstarter/customer-modules/enums';
import { getCleanupFieldData, MQHowQuotingWorksModal } from '@lawnstarter/customer-modules/helpers';
import {
  getTrackingEventSource,
  useOrderServiceViewedTracking,
} from '@lawnstarter/customer-modules/hooks';
import { t } from '@lawnstarter/customer-modules/services';
import {
  orderServices_submitQuote,
  properties_currentPropertyIdSelector,
} from '@lawnstarter/customer-modules/stores/modules';
import { Button } from '@lawnstarter/ls-react-common/atoms';
import { ServiceType } from '@lawnstarter/ls-react-common/enums';
import { useApiTracking, useModal } from '@lawnstarter/ls-react-common/hooks';
import {
  CleanupForm,
  CleanupFormData,
  ModalTemplate,
} from '@lawnstarter/ls-react-common/organisms';

import { OrderServiceHeader } from '@src/components';
import {
  useAutoScrollOnFormErrors,
  useDispatch,
  useRouteNavigation,
  useSelector,
} from '@src/hooks';
import { errorService } from '@src/services';

import { StyledOrderWrapper } from './styles';

export function OrderCleanupScreen() {
  const dispatch = useDispatch();
  const navigation = useRouteNavigation();
  const location = useLocation();
  const propertyId = useSelector(properties_currentPropertyIdSelector);
  const isUpdating = useSelector(({ orderServices }) => orderServices.loadingStatus.isUpdating);
  const stepModal = useModal(ModalTemplate.STEPS);
  const carouselAbTest = useFlags([FeatureFlags.RECOMMENDATION_CAROUSEL_AB_TEST])[
    FeatureFlags.RECOMMENDATION_CAROUSEL_AB_TEST
  ];

  const apiTracking = useApiTracking()!;
  const source = carouselAbTest.enabled
    ? getTrackingEventSource(location.state?.source, carouselAbTest.value)
    : undefined;
  useOrderServiceViewedTracking(apiTracking, ServiceType.Cleanups, errorService, source);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, submitCount },
  } = useForm<CleanupFormData>({
    defaultValues: {
      budget: '',
      details: '',
      locations: [],
      other_debris: '',
      last_service: '',
      pendingImages: [],
      debris_to_remove: [],
      desired_haulaway: true,
      desired_bed_cleanup: undefined,
    },
  });

  useAutoScrollOnFormErrors({ errors, submitCount });

  const submit = async ({
    budget,
    details,
    locations,
    other_debris,
    last_service,
    pendingImages,
    debris_to_remove,
    desired_haulaway,
    desired_bed_cleanup,
  }: CleanupFormData) => {
    const result = await dispatch(
      orderServices_submitQuote({
        updatedFields: {
          service_id: 7,
          pendingImages,
          property_id: propertyId,
          service_str_id: 'cleanup_spring',
          budget: parseFloat(budget.toString()).toFixed(2),
          locations: {
            ...Object.assign({}, ...locations.map((location) => ({ [location]: true }))),
          },
          cleanup_spring_additional: {
            details,
            last_service,
            desired_haulaway,
            desired_bed_cleanup,
            ...(debris_to_remove.includes('has_other') && { other_debris }),
            ...Object.assign({}, ...debris_to_remove.map((debris) => ({ [debris]: true }))),
          },
        },
        source,
      }),
    );

    if (result?.success) {
      navigation.navigate(WebRoutes.services);
    }
  };

  return (
    <StyledOrderWrapper>
      <OrderServiceHeader
        section={t('forms.quote.request')}
        serviceName={t('forms.cleanups.service')}
      />

      <div role="form">
        <CleanupForm
          control={control}
          isUpdating={isUpdating}
          watch={watch}
          fieldData={getCleanupFieldData()}
          infoModal={() => stepModal.show(MQHowQuotingWorksModal())}
        />

        <Button
          mode="contained"
          loading={isUpdating}
          disabled={isUpdating}
          onPress={handleSubmit(submit)}
          trackID="browse-screen_order-cleanups-submit-btn"
        >
          {t('forms.getPrice')}
        </Button>
      </div>
    </StyledOrderWrapper>
  );
}
